@tailwind base;
@tailwind components;
@tailwind utilities;

.primary-color {
  @apply text-transparent bg-clip-text bg-gradient-to-r from-orange-400 to-pink-600  inline-block;
}

body {
  background-color: black;
}

.bg-primary-color {
  @apply bg-gradient-to-r from-orange-400 to-pink-600  inline-block;
}
